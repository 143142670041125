<!-- 勘探安装数据 -->
<template>
  <div class="expoleManageBox bgblue">
    <div class="top"></div>
    <div class="form">
      <el-form
        :model="form"
        class="demo-form-inline"
        style="position: relative"
        label-width="110px"
      >
        <el-row>
          <el-col :span="4"
            ><el-form-item label="勘探编号">
              <el-input
                v-model="form.user"
                placeholder="请输入勘探编号"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="4"
            ><el-form-item label="安装编号">
              <el-input
                v-model="form.user"
                placeholder="请输入安装编号"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="4"
            ><el-form-item label="客户姓名">
              <el-input
                v-model="form.customerName"
                placeholder="请输入客户姓名"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="4">
            <el-form-item label="手机">
              <el-input
                v-model="form.customerPhone"
                placeholder="请输入手机号"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="4">
            <el-form-item label="证件号">
              <el-input
                v-model="form.certificateNo"
                placeholder="请输入证件号"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="4"
            ><el-form-item label="金融单号">
              <el-input
                v-model="form.user"
                placeholder="请输入金融单号"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="4"
            ><el-form-item label="电站PSID">
              <el-input
                v-model="form.user"
                placeholder="请输入电站PSID"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="4">
            <el-form-item label="屋顶类型">
              <el-select
                v-model="roofType.region"
                placeholder="请选择屋顶类型"
                style="width: 100%"
              >
                <el-option
                  v-for="item in roofType"
                  :key="item.region"
                  :label="item.label"
                  :value="item.region"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4"
            ><el-form-item label="屋顶数量">
              <el-select
                v-model="roofNum.region"
                placeholder="请选择屋顶数量"
                style="width: 100%"
              >
                <el-option
                  v-for="item in roofNum"
                  :key="item.region"
                  :label="item.label"
                  :value="item.region"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
          <div v-if="advanced">
            <el-col :span="4"
              ><el-form-item label="安装方式">
                <el-select
                  v-model="installType.region"
                  placeholder="请选择安装方式"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in installType"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="4"
              ><el-form-item label="逆变器SN号">
                <el-select
                  v-model="inverterSN.region"
                  placeholder="请选择逆变器SN号"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in inverterSN"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="代理商名称">
                <el-input
                  v-model="form.user"
                  placeholder="请输入代理商名称"
                ></el-input> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="勘探状态">
                <el-select
                  v-model="explorationStatus.region"
                  placeholder="请选择代理商名称"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in explorationStatus"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="安装状态">
                <el-select
                  v-model="installationStatus.region"
                  placeholder="请选择安装状态"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in installationStatus"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="复查结果">
                <el-select
                  v-model="results.region"
                  placeholder="请选择复查结果"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in results"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="4"
              ><el-form-item label="审核人">
                <el-input
                  v-model="form.user"
                  placeholder="请输入审核人"
                ></el-input> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="安全驳回次数">
                <el-select
                  v-model="securityRejections.region"
                  placeholder="请选择安全驳回次数"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in securityRejections"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="合作类型">
                <el-select
                  v-model="cooperationType.region"
                  placeholder="请选择合作类型"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in cooperationType"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="异常情况">
                <el-select
                  v-model="explorationAnomalies.region"
                  placeholder="请选择异常"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in explorationAnomalies"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="勘探地区">
                <el-select
                  v-model="explorationArea.region"
                  placeholder="请选择勘探地区"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in explorationArea"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="所属分公司">
                <el-select
                  v-model="firm.region"
                  placeholder="请选择分公司"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in firm"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="复查人">
                <el-input
                  v-model="form.user"
                  placeholder="请输入复查人"
                ></el-input> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="勘探数据类型">
                <el-select
                  v-model="dataType.region"
                  placeholder="请选择勘探数据类型"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in dataType"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="安全单据类型">
                <el-select
                  v-model="securityDocument.region"
                  placeholder="请选择安全单据类型"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in securityDocument"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item label="井网方式">
                <el-select
                  v-model="wellPattern.region"
                  placeholder="请选择井网方式"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in wellPattern"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="8">
              <el-form-item label="按勘探审批时间">
                <el-date-picker
                  style="width: 100%"
                  v-model="value1"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </div>
          <el-col :span="4">
            <el-button type="text" @click="toggleAdvanced"
              ><i
                :class="advanced ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
              ></i
              >{{ advanced ? "收起" : "展开" }}</el-button
            >
            <el-button type="primary" size="small" @click="search">
              <i class="el-icon-search"></i>查询</el-button
            >
            <el-button plain size="small">
              <i class="el-icon-refresh"></i>重置</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="bgwhite">
      <el-row>
        <el-button type="primary" plain size="small" @click="exprotExcel">
          <i class="el-icon-upload2"></i>&nbsp;&nbsp;导出</el-button
        >
        <!-- <el-button type="primary" plain @click="$router.push('expoleManage/exploeIncrease')">
          <i class="el-icon-plus"></i>&nbsp;&nbsp;新增</el-button
        > -->
      </el-row>
      <div class="table">
        <el-table
          :data="tableData"
          border
          height="calc(100vh - 300px)"
          :header-cell-style="{ background: '#f2f2f2', color: '#333' }"
          @row-dblclick="handleRowDoubleClick"
        >
          <el-table-column
            type="index"
            align="center"
            label="序号"
          ></el-table-column>
          <el-table-column
            prop="agentName"
            label="所属公司"
            width="90px"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column prop="customerName" label="客户名称" align="center">
          </el-table-column>
          <el-table-column prop="customerNumber" label="客户编号" width="111">
            <template v-slot="{ row }">
              <span style="color: #409eff">{{ row.customerNumber }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="acheiveNum"
            label="技术审核状态"
            width="95"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="btn-blue"
                v-if="scope.row.techexamineStatus == 0"
                >待审核</el-button
              >
              <el-button
                type="text"
                class="btn-green"
                v-if="scope.row.techexamineStatus == 1"
                >通过</el-button
              >
              <el-button
                type="text"
                class="btn-red"
                v-if="scope.row.techexamineStatus == 2"
                >驳回</el-button
              >
              <!-- <span style="color: #409eff" v-if="scope.row.techexamineStatus==0">未审核</span>
            <span style="color: #409eff" v-if="scope.row.techexamineStatus==1">通过</span>
            <span style="color: #409eff" v-if="scope.row.techexamineStatus==2">驳回</span> -->
            </template>
          </el-table-column>
          <el-table-column
            prop="acheiveNum"
            label="商务审核状态"
            width="95"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="btn-blue"
                v-if="scope.row.businessexamineStatus == 0"
                >待审核</el-button
              >
              <el-button
                type="text"
                class="btn-green"
                v-if="scope.row.businessexamineStatus == 1"
                >通过</el-button
              >
              <el-button
                type="text"
                class="btn-red"
                v-if="scope.row.businessexamineStatus == 2"
                >驳回</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="acheiveNum"
            label="安装编号"
            width="150"
            align="center"
          >
            <template slot-scope="scope">
              <span style="color: #409eff">{{ scope.row.exploeNum }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="agentName" show-overflow-tooltip label="代理商名称" width="83">
          </el-table-column>
          <el-table-column prop="area" label="片区" width="50px" align="center">
          </el-table-column>
          <el-table-column
            prop="customerPhone"
            label="手机"
            width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="certificateNo"
            label="证件号"
            width="150"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="installionState"
            label="安装状态"
            width="100px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="cooperationType"
            label="合作类型"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="reviewResults" label="复查结果" align="center">
          </el-table-column>
          <el-table-column prop="review" label="复查人" align="center">
          </el-table-column>
          <el-table-column
            prop="installationRejection"
            label="安装驳回次数"
            width="100px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="explorationRejection"
            label="勘探驳回次数"
            width="100px"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="explorationData"
            label="勘探数据类型"
            width="100px"
          >
          </el-table-column>
          <el-table-column
            prop="installationDatatype"
            label="安装单据类型"
            width="100px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="colorSteel"
            label="是否封装彩刚"
            width="100px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="explorationState"
            label="勘探状态"
            width="100px"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="powerStation" label="电站类型" align="center">
          </el-table-column>
          <el-table-column
            prop="explorationAnomalies"
            label="勘探异常情况"
            width="100px"
            align="center"
          >
            <el-button class="btn-green" type="text">正常</el-button>
          </el-table-column>
          <el-table-column
            prop="installationException"
            label="安装异常类型"
            width="100px"
            align="center"
          >
            <el-button class="btn-green" type="text">正常</el-button>
          </el-table-column>
          <el-table-column
            prop="explorationSinglechip "
            label="勘探单片功率(W)"
            width="120px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="componentSpecifications"
            label="组件规格"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="installationTypety"
            label="安装方式"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="roofTypess" label="屋顶类型" align="center">
          </el-table-column>
          <el-table-column
            prop="gridconnectionType"
            label="并网方式"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="numberPlanblocks"
            label="方案块数(块)"
            width="100px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="explorationPower"
            label="勘探功率"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="explorationAddress"
            label="勘探地址"
            width="200px"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="baseAddress"
            label="基准地址"
            width="200px"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="explorationRemarks"
            label="勘探备注"
            width="200px"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="explorationCreator"
            label="勘探创建人"
            width="100px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="explorationCreationtime"
            label="勘探创建时间"
            width="150px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="explorationSubmitter"
            label="勘探提交人"
            width="100px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="explorationReviewtime"
            label="勘探提交时间"
            width="150px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="installingSinglechippower"
            label="勘探审核人"
            width="100px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="numberofInstallationblocks"
            label="勘探审核时间"
            width="150px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="installionPower"
            label="安装单片功率(W)"
            width="120px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="inverterColumnnumber"
            label="安装块数(块)"
            width="100px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="numberofInverters"
            label="安装功率(W)"
            width="100px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="installationNumer"
            label="逆变器列序号"
            width="100px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="numberofInverters"
            label="逆变器台数"
            width="100px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="installationAddresss"
            label="安装地址"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="installationNotes"
            label="安装备注"
            width="280px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="installationCreator"
            label="安装创建人"
            width="100px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="installationCreationtime"
            label="安装创建时间"
            width="280px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="installationSubmitter"
            label="安装提交人"
            width="90px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="installationSubmissiontime"
            label="安装提交时间"
            width="100px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="installedBy"
            label="安装更新人"
            width="100px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="installationUpdatetime"
            label="安装更新时间"
            width="100px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="installationPreliminaryreviewer"
            label="安装初审人"
            width="90px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="installationInitialreviewtime "
            label="安装初审时间"
            width="100px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="installationReviewperson"
            label="安装复审人"
            width="100px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="installationReviewtime"
            label="安装复审时间"
            width="100px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="financialTrackingnumber"
            label="金融单号"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="powerStationPSID"
            label="电站PSID"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="explorationReviewsubmissiontime"
            label="勘探复查提交时间"
            width="130px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="installationReviewsubmissiontime"
            label="安装复查提交时间"
            width="130px"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop=" "
            label="操作"
            width="80px"
            align="center"
            fixed
          >
            <template v-slot="{ row }">
              <el-button type="text" class="btn-blue" @click="goDetail(row)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currPage"
          :page-sizes="[20, 30, 50, 100]"
          :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.getData();
    setTimeout(() => {
      console.log(111);
      location.reload();
    }, 5 * 60 * 1000); // 5分钟 = 5 * 60秒 * 1000毫秒
  },
  methods: {
    handleClick(row) {
      console.log(row);
    },
    goDetail(row) {
      console.log(111, 845);
      console.log(row.programexamineStatus, 819);
      if (row.programexamineStatus == 0 || row.programexamineStatus == 2) {
        this.$router.push(
          `/expoleManageDetail?explorateCode=${row.explorateCode}`
        );
      }
      if (row.programexamineStatus == 1 || row.programexamineStatus == 3) {
        this.$router.push(
          `/expoleManageDetailShow?explorateCode=${row.explorateCode}`
        );
      }
    },
    onSubmit() {
      console.log("submit!");
    },
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.params.currPage = val;
      this.getData();
    },
    toggleAdvanced() {
      this.advanced = !this.advanced;
    },
    exprotExcel() {
      console.log(111);
      this.$http.post("/houseAgentBilldetail/export", {}).then(res => {
        console.log(res);
      });
    },
    handleRowDoubleClick(row, column, event) {
      this.$router.push(
        `/expoleManageDetailShow?explorateCode=${row.explorateCode}`
      );
      console.log(row.explorateCode, 844);
    },
    getData() {
      let data = {
        condition: this.form,
        ...this.params
      };
      this.$http
        .post("/houseExplorateBase/platformHouseExplorateBaseList", data)
        .then(res => {
          console.log(res);
          if (res.data.code == 200) {
            this.tableData = res.data.data.data;
            this.total = res.data.data.count;
          } else {
            this.$message({
              type: "error", // success error warning
              message: res.data.message,
              duration: 2000
            });
          }
        });
    },
    search() {
      console.log(111);
      this.getData();
    }
  },

  data() {
    return {
      tableData: [
        // {
        //   company: "皖北分公司",
        //   name: "陈国芳",
        //   exploeNum: "202302281545082308",
        //   acheiveNum: "202304131400474780",
        //   agentName: "亳州市来裕新能源科技有限公司",
        //   area: "李朋",
        //   phone: "18709885078",
        //   idNumber: "341281196610104663",
        //   installionState: "安装审批结束",
        //   cooperationType: "惠农宝",
        //   reviewResults: "未复查",
        //   review: "---",
        //   installationRejection: "1",
        //   explorationRejection: "0",
        //   explorationData: "---",
        //   installationDatatype: "普通单据",
        //   colorSteel: "否",
        //   explorationState: "勘探审批结束",
        //   powerStation: "用户电站",
        //   explorationAnomalies: "",
        //   installationException: "",
        //   explorationSinglechip: "660W",
        //   componentSpecifications: "72版型",
        //   installationType: "人字坡(低风压)七排",
        //   roofType: "阳光棚屋顶",
        //   gridconnectionType: "单户入网",
        //   numberPlanblocks: "46",
        //   explorationPower: "36960",
        //   explorationAddress: "安徽省亳州市谯城区309省道32-4号靠近福华驾校",
        //   baseAddress: "安徽省亳州市谯城区309省道32-4号靠近福华驾校",
        //   explorationRemarks:
        //     "房屋高11米,现浇顶,顶板厚度25公分,阳光棚7排,南4北3,南北出墙70公分,东西不出墙，施工前，彩钢瓦移除，无其他遮挡",
        //   explorationCreator: "秦祥宇",
        //   explorationCreationtime: "2023-02-28 15:45:04",
        //   explorationSubmitter: "秦祥宇",
        //   explorationReviewtime: "2023-02-28 16:07:12",
        //   installingSinglechippower: "陶鼎文",
        //   numberofInstallationblocks: "2023-02-28 16:07:25",
        //   installionPower: "660W",
        //   inverterColumnnumber: " ",
        //   numberofInverters: "46",
        //   installationAddress: "TM30KBT221114128",
        //   installationNotes: "安徽省亳州市谯城区中心街靠近城父福华驾校",
        //   installationCreator: "1",
        //   installationCreationtime: "安徽省亳州市谯城区中心街靠近城父福华驾校",
        //   installationSubmitter: "",
        //   installationSubmissiontime: "",
        //   installedBy: "",
        //   installationUpdatetime: "",
        //   installationPreliminaryreviewer: "",
        //   installationInitialreviewtime: "",
        //   installationReviewperson: "",
        //   installationReviewtime: "",
        //   financialTrackingnumber: "",
        //   powerStationPSID: "",
        //   explorationReviewsubmissiontime: "",
        //   installationReviewsubmissiontime: "",
        // },
      ],
      total: 0,
      params: {
        count: 0,
        currPage: 1,
        pageCount: 0,
        pageSize: 20
      },
      form: {
        dataStatus: 1
      },
      roofType: [],
      roofNum: [],
      inverterSN: [],
      installType: [],
      explorationStatus: [],
      installationStatus: [],
      results: [],
      securityRejections: [],
      cooperationType: [],
      explorationAnomalies: [
        {
          region: "1",
          label: "勘探异常"
        },
        {
          region: "2",
          label: "安装异常"
        }
      ],
      explorationArea: [],
      firm: [],
      dataType: [],
      securityDocument: [],
      wellPattern: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      value1: "",
      value2: "",
      currentPage4: 1,
      value: "",
      advanced: false
    };
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-table td {
  padding: 0px !important;
}

::v-deep .el-table th {
  padding: 0px !important;
}

.expoleManageBox {
  .el-form .el-row .el-col-4 {
    width: 19.5%;
  }
  .el-form-item__label {
    font-size: 13px;
  }
  margin-right: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  .el-date-editor .el-range__icon {
    line-height: 21px !important;
  }

  .top {
    margin-bottom: 10px;
  }
  .form {
    background-color: #fff;
    margin: 10px;
    padding: 10px 5px 0 5px;
    border: 1px solid #ececec;
    border-radius: 5px;
  }
  .pagination {
    float: right;
  }
  .bgwhite {
    margin: 10px;
    padding: 10px;
    overflow: hidden;
    background-color: white;
    border: 1px solid #ececec;
  }
  // .el-button {
  //   padding: 0 10px;
  // }
}

::v-deep .el-table--small th {
  padding: 0 !important;
}
</style>
